import Profile from '@/components/General/Profile';
import { mapActions } from 'vuex';
import { MODULE_NAME as USER_MODULE, ACTIONS as USER_ACTIONS } from '@/store/modules/user/types';
import UserTransformers from '@/transformers/user';

export default {
  name: 'PublicProfile',

  components: {
    Profile,
  },

  data() {
    return {
      user: {},
    };
  },

  mounted() {
    this.getUserOrRedirect();
  },

  methods: {
    ...mapActions(USER_MODULE, {
      getUserByUsername: USER_ACTIONS.GET_USER_BY_USERNAME,
    }),

    async getUserOrRedirect() {
      const username = this.$route.params.username;
      const user = await this.getUserByUsername(username);

      if (!user) {
        return this.$router.push({ name: 'error-not-found' });
      }

      this.user = UserTransformers(user);
    },
  },
};
