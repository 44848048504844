import AppConfig from '@/config/app';
import Modal from '@/components/General/Modal';
import { MODULE_NAME as AUTH_MODULE, GETTERS as AUTH_GETTERS } from '@/store/modules/auth/types';
import { MODULE_NAME as USER_MODULE, ACTIONS as USER_ACTIONS } from '@/store/modules/user/types';
import { mapGetters, mapActions } from 'vuex';
import FooterSimple from '@/components/General/FooterSimple';

export default {
  name: 'Profile',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  components: { Modal, FooterSimple },

  data() {
    return {
      appName: AppConfig.NAME,
      showModalOptions: false,
      modalPosition: 'bottom',
    };
  },

  computed: {
    ...mapGetters(AUTH_MODULE, {
      loggedUser: AUTH_GETTERS.USER,
      isLoggedIn: AUTH_GETTERS.IS_LOGGED_IN,
    }),

    socialLinks() {
      return this.user.socialLinks || [];
    },

    customLinks() {
      return this.user.customLinks || [];
    },

    currentYear() {
      return new Date().getFullYear();
    },

    canShowModalOptions() {
      return this.showModalOptions;
    },

    isMyProfile() {
      return this.isLoggedIn && this.loggedUser.userId == this.user.id;
    },
  },

  methods: {
    ...mapActions(USER_MODULE, {
      getMyUser: USER_ACTIONS.MY_USER,
    }),

    getSocialLinkIconClass(socialLinkName) {
      return `fab fa-${socialLinkName}`;
    },

    onClickSocialLink(socialLink) {
      const isValid = this.isValidURL(socialLink.link);

      if (!isValid) {
        alert('Invalid URL!');
        return;
      }

      window.location.href = this.parseLink(socialLink.link);
    },

    onClickCustomLink(customLink) {
      const isValid = this.isValidURL(customLink.link);

      if (!isValid) {
        alert('Invalid URL!');
        return;
      }

      window.location.href = this.parseLink(customLink.link);
    },

    parseLink(link) {
      let newLink = link.trim();

      // Check if custom link is missing www and https
      if (link.indexOf('www.') === -1 && link.indexOf('https://') === -1) {
        newLink = `//www.${link}`;
      }

      return newLink;
    },

    isValidURL(link) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      );

      return !!pattern.test(link);
    },

    onClickOptions() {
      this.showModalOptions = true;
    },

    onCloseModalOptions() {
      this.showModalOptions = false;
    },

    async onClickCopyLink() {
      const urlToCopy = 'cpnow.co/' + this.user.username;

      await navigator.clipboard.writeText(urlToCopy);

      this.notifyInfo();
      this.showModalOptions = false;
    },

    onClickEditProfile() {
      this.$router.push({ name: 'profile-editor' });
    },

    async onClickVisitMyProfile() {
      const loggedUserProfile = await this.getMyUser();
      const loggedUserUsername = loggedUserProfile.username;

      window.location.href = AppConfig.URL + '/profile/' + loggedUserUsername;
    },

    onClickCreateYourOwnProfile() {
      this.$router.push({ name: 'sign-up' });
    },

    onClickReport() {
      this.notifyInfo();
      this.showModalOptions = false;
    },
  },
};
