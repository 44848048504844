export default {
  name: 'Modal',

  props: {
    position: {
      type: String,
      default: 'bottom',
    },
  },

  computed: {
    modalClasses() {
      return {
        'bg-white': true,
        'text-dark-blue': true,
        'text-center': true,
        'rounded-t-[10px]': true,
        'p-2': true,
        'modal__modal-wrapper__modal-dialog': true,
        'modal__modal-wrapper__modal-dialog__modal-content': true,
        'modal__modal-wrapper__modal-dialog__modal-content__modal-body': true,
        'modal--bottom': this.position === 'bottom',
        'modal--center': this.position === 'center',
      };
    },
  },
};
